@font-face {
    font-family: rockwellLocal;
    src: url(../fonts/rockwell.ttf);
}

html,
body{
    font-family: rockwellLocal !important;
    background: #f5f0ec;
    height: 100%;
    min-width: 100%;
    width: fit-content;
}

.container-fluid {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(100vh);
    padding-right: 0;
    padding-left: 0;
    /* padding-bottom: 4.2rem; */
    width: 100%;
}

.founder-item {
    padding: 0 5% 10px 0;
    text-align: center;
}

.founder-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.founder-img {
    max-width: 200px;
    border-radius: 10px;
}

.founder-list-item {
    list-style: none;
}

.input-label {
    padding: 10px 0 0 0;
}

.juggler-container {
    position: relative;
}

.mail-sent-label {
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: 2vh;
}

.maps {
    margin: 2vh 0;
    width: 600px;
    height: 450px;
}

.navbar {
    background-color: #8f7c65;
}

.navbar-nav {
    float:none;
    margin:0 auto;
    text-align: center;
}

.navbar-nav > li {
    display: inline-block;
    float:none;
}

.nav-link {
    color: orange !important;
    font-size: 2.0vh;
    margin-right: 1vh;
}

.njf-archive-media-section {
    text-align: center;
    padding-bottom: 40px;
}

.njf-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* height: 4.2rem; */
    display: flex;
    justify-content: center;
    margin-top: auto;
    background-color: #dbbf9c !important;
}

.njf-grid {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-template-rows: auto;
    grid-template-areas: "left center right";
}

.njf-grid-center {
    grid-area: center;
}

.njf-grid-left {
    grid-area: left;
}

.njf-grid-right {
    grid-area: right;
}

.njf-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    padding-right: 0;
    padding-left: 0;
}

.njf-submit-button {
    background-color: #baa086;
    border-color: #baa086;
    margin-top: 1vh;
}

.page-header {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.text-field {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    margin: 6px 0 16px 0;
}

table tr:last-child {
    border-bottom: none;
}

.notFoundImage {
    text-align: center;
    margin: 10px 0;
}

.record-article h3 {
    text-align: center;
}

.records {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /*justify-content: space-between;*/
}

.record_general {
}

.record_women {
}

@media only screen and (max-width: 500px) {
    img {
        width: 200px;
        height: 100px;
    }

    .njf-article {
        margin-bottom: 100px;
    }

    .njf-footer-2 {
        flex-direction: column;
    }

    .maps {
        margin: 2vh 0;
        width: 300px;
        height: 225px;
        margin-bottom: 100px;
    }

    .record-article {
        margin-bottom: 100px;
        display: ruby;
        /*justify-content: space-between;*/
    }

    .records{
        display: ruby;
    }

    .recordTable table, thead, tbody, th, td, tr {
        display: block;
        min-width: 300px;
    }

    .recordTable thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.recordTable tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .recordFiller {
        height: 26px;
    }

    .recordAantal {
        height: 24px;
    }

    .recordRecordHouder {
        height: 50px;
    }

    .recordRecord {
        height: 24px;
    }

    .recordVideo {
        height: 50px;
    }

    .recordNotes {
        height: 24px;
    }

	.recordTable td {
		border: none;
		border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
	}

	.recordTable td:before {
		position: absolute;
        left: 6px;
		padding-right: 10px;
        white-space: nowrap;
    }

	.recordTable td:nth-of-type(1):before { content: ""; }
	.recordTable td:nth-of-type(2):before { content: "Aantal"; }
	.recordTable td:nth-of-type(3):before { content: "Recordhouder(s)"; }
	.recordTable td:nth-of-type(4):before { content: "Record"; }
	.recordTable td:nth-of-type(5):before { content: "Videolink(s)"; }
    .recordTable td:nth-of-type(6):before { content: "Notities"; }

    .njf-edition-chart {
        min-width: 300px;
        padding: 0px;
        margin-bottom: 2vh;
    }

    /* Region edition table */
    .editionTable {
        margin-bottom: 50px;
    }

    .editionTable table, thead, tbody, th, td, tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
	.editionTable thead tr {
		position: absolute;
		top: -9999px;
        left: -9999px;
	}

	.editionTable tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

	.editionTable td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
        position: relative;
		padding-left: 50%;
	}

	.editionTable td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		left: 6px;
		padding-right: 10px;
		white-space: nowrap;
    }

    /*
	Label the data
	*/
	.editionTable td:nth-of-type(1):before { content: "Editie"; }
	.editionTable td:nth-of-type(2):before { content: "Locatie"; }
	.editionTable td:nth-of-type(3):before { content: "Jaar"; }
	.editionTable td:nth-of-type(4):before { content: "Totaal bezoekers"; }
	.editionTable td:nth-of-type(5):before { content: "Voorinschrijving"; }
	.editionTable td:nth-of-type(6):before { content: "Bezoekers donderdag"; }
	.editionTable td:nth-of-type(7):before { content: "Bezoekers vrijdag"; }
	.editionTable td:nth-of-type(8):before { content: "Bezoekers zaterdag"; }
	.editionTable td:nth-of-type(9):before { content: "Bezoekers zondag"; }
    .editionTable td:nth-of-type(10):before { content: "Bezoekers volledig festival"; }
    .editionTable td:nth-of-type(10):before { content: "Bezoekers jeugd"; }
    /* Endregion edition table */
}

@media only screen and (max-width: 900px) and (min-width: 500px) {
    img {
        width: 240px;
        height: 120px;
    }

    .njf-article {
        margin-bottom: 100px;
    }

    .njf-footer-2 {
        flex-direction: column;
    }

    .maps {
        margin: 2vh 0;
        width: 400px;
        height: 300px;
        margin-bottom: 100px;
    }

    .records{
        display: ruby;
    }

    .record-article {
        margin-bottom: 100px;
        display: ruby;
    }

    .recordTable table, thead, tbody, th, td, tr {
        display: block;
        min-width: 420px;
    }

	.recordTable thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.recordTable tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .recordFiller {
        height: 26px;
    }

    .recordAantal {
        height: 24px;
    }

    .recordRecordHouder {
        height: 50px;
    }

    .recordRecord {
        height: 24px;
    }

    .recordVideo {
        height: 50px;
    }

    .recordNotes {
        height: 24px;
    }

	.recordTable td {
		border: none;
		border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
	}

	.recordTable td:before {
		position: absolute;
        left: 6px;
		padding-right: 10px;
        white-space: nowrap;
    }

	.recordTable td:nth-of-type(1):before { content: ""; }
	.recordTable td:nth-of-type(2):before { content: "Aantal"; }
	.recordTable td:nth-of-type(3):before { content: "Recordhouder(s)"; }
	.recordTable td:nth-of-type(4):before { content: "Record"; }
	.recordTable td:nth-of-type(5):before { content: "Videolink(s)"; }
	.recordTable td:nth-of-type(6):before { content: "Notities"; }
    /* Endregion record table */

    .njf-edition-chart {
        min-width: 420px;
        padding: 0px;
        margin-bottom: 2vh;
    }

    /* Region edition table */
    .editionTable {
        margin-bottom: 50px;
    }

    .editionTable table, thead, tbody, th, td, tr {
        display: block;
    }

    .editionTable tr {
        min-width: 420px;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
	.editionTable thead tr {
		position: absolute;
		top: -9999px;
        left: -9999px;
	}

	.editionTable tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

	.editionTable td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
        position: relative;
		padding-left: 50%;
	}

	.editionTable td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		left: 6px;
		padding-right: 10px;
		white-space: nowrap;
    }

    /*
	Label the data
	*/
	.editionTable td:nth-of-type(1):before { content: "Editie"; }
	.editionTable td:nth-of-type(2):before { content: "Locatie"; }
	.editionTable td:nth-of-type(3):before { content: "Jaar"; }
	.editionTable td:nth-of-type(4):before { content: "Totaal bezoekers"; }
	.editionTable td:nth-of-type(5):before { content: "Voorinschrijving"; }
	.editionTable td:nth-of-type(6):before { content: "Bezoekers donderdag"; }
	.editionTable td:nth-of-type(7):before { content: "Bezoekers vrijdag"; }
	.editionTable td:nth-of-type(8):before { content: "Bezoekers zaterdag"; }
	.editionTable td:nth-of-type(9):before { content: "Bezoekers zondag"; }
    .editionTable td:nth-of-type(10):before { content: "Bezoekers volledig festival"; }
    .editionTable td:nth-of-type(10):before { content: "Bezoekers jeugd"; }
    /* Endregion edition table */
}

@media only screen and (max-width: 1400px) and (min-width: 900px) {
    img {
        width: 260px;
        height: 130px;
    }

    .njf-article {
        margin-bottom: 100px;
    }

    .njf-footer-2 {
        flex-direction: column;
    }

    .navbar-brand {
        margin: 0px;
    }

    .navbar-nav {
        margin: 0px;
    }

    .nav-link {
        font-size: x-large;
        margin-right: 0vh;
    }

    .maps {
        margin: 2vh 0;
        width: 600px;
        height: 450px;
        margin-bottom: 100px;
    }

    .founder-list-item {
        list-style: none;
    }

    .input-label {
        padding: 0 0 0 0;
    }

    .juggler-container {
        position: relative;
    }

    .mail-sent-label {
        margin-top: 2vh;
        margin-bottom: 2vh;
        font-size: 2vh;
    }

    .navbar {
        background-color: #8f7c65;
    }

    .njf-article {
        margin-bottom: 100px;
        padding: 0;
    }

    .njf-edition-chart {
        min-width: 420px;
        padding: 0px;
        margin-bottom: 2vh;
    }

    .njf-grid {
        display: grid;
        grid-template-columns: 10% 80% 10%;
        grid-template-rows: auto;
        grid-template-areas: "left center right";
    }

    .njf-grid-center {
        grid-area: center;
    }

    .njf-grid-left {
        grid-area: left;
    }

    .njf-grid-right {
        grid-area: right;
    }

    .njf-root {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    .njf-submit-button {
        background-color: #baa086;
        border-color: #baa086;
        margin-top: 1vh;
    }

    .page-header {
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
    }

    .text-field {
        padding: 10px;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
        margin: 6px 0 16px 0;
    }

    table tr:last-child {
        border-bottom: none;
    }

    .record-article {
        margin-bottom: 100px;
        display: ruby;
    }

    .records{
        display: ruby;
    }

    .recordTable {
        margin-bottom: 10px;
    }

    .recordRow {
        width: 100%;
        display: flex;
        column-gap: 20px;
        border-bottom: 1px solid #ccc;
    }

    .recordFiller{
        width: 25px;
        height: 26px;
    }

    .recordAantal{
        width: 25px;
    }

    .recordRecordHouder{
        width: 200px;
    }

    .recordRecord{
        width: 115px;
    }

    .recordVideo{
        width: 45px;
    }

    .recordNotes{
        font-size: 12px;
        color: #a00;
        width: 100px;
    }

    .recordGroup {
        display: flex;
        align-content: space-between;
        gap: 10px;
    }

    .recordInput {
        flex: 1;
    }
    /* Endregion record table */

    /* Region edition table */
    .editionTable {
        margin-bottom: 50px;
    }

    .editionTable table, thead, tbody, th, td, tr {
        display: block;
    }

    .editionTable tr {
        min-width: 420px;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
	.editionTable thead tr {
		position: absolute;
		top: -9999px;
        left: -9999px;
	}

	.editionTable tr {
        border: 1px solid #ccc;
        margin-bottom: 10px;
    }

	.editionTable td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
        position: relative;
		padding-left: 50%;
	}

	.editionTable td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		left: 6px;
		padding-right: 10px;
		white-space: nowrap;
    }

    /*
	Label the data
	*/
	.editionTable td:nth-of-type(1):before { content: "Editie"; }
	.editionTable td:nth-of-type(2):before { content: "Locatie"; }
	.editionTable td:nth-of-type(3):before { content: "Jaar"; }
	.editionTable td:nth-of-type(4):before { content: "Totaal bezoekers"; }
	.editionTable td:nth-of-type(5):before { content: "Voorinschrijving"; }
	.editionTable td:nth-of-type(6):before { content: "Bezoekers donderdag"; }
	.editionTable td:nth-of-type(7):before { content: "Bezoekers vrijdag"; }
	.editionTable td:nth-of-type(8):before { content: "Bezoekers zaterdag"; }
	.editionTable td:nth-of-type(9):before { content: "Bezoekers zondag"; }
    .editionTable td:nth-of-type(10):before { content: "Bezoekers volledig festival"; }
    .editionTable td:nth-of-type(10):before { content: "Bezoekers jeugd"; }
    /* Endregion edition table */
}

@media only screen and (min-width: 1400px) and (max-width:1524px) {
    .record-article {
        margin-bottom: 100px;
        display: ruby;
    }

    .records{
        display: ruby;
    }
}

@media only screen and (min-width: 1400px) {
    .founder-list-item {
        list-style: none;
    }

    .input-label {
        padding: 10px 0 0 0;
    }

    .juggler-container {
        position: relative;
    }

    .mail-sent-label {
        margin-top: 2vh;
        margin-bottom: 2vh;
        font-size: 2vh;
    }

    .maps {
        margin: 2vh 0;
        width: 600px;
        height: 450px;
        margin-bottom: 100px;
    }

    .navbar {
        background-color: #8f7c65;
    }

    .njf-article {
        margin-bottom: 100px;
        padding: 0 10%;
    }

    .njf-edition-chart {
        padding: 10px;
        margin-bottom: 2vh;
        min-width: 570px;
    }

    .njf-grid {
        display: grid;
        grid-template-columns: 10% 80% 10%;
        grid-template-rows: auto;
        grid-template-areas: "left center right";
    }

    .njf-grid-center {
        grid-area: center;
    }

    .njf-grid-left {
        grid-area: left;
    }

    .njf-grid-right {
        grid-area: right;
    }

    .njf-root {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        padding-right: 0;
        padding-left: 0;
    }

    .njf-submit-button {
        background-color: #baa086;
        border-color: #baa086;
        margin-top: 1vh;
    }

    .page-header {
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
    }

    .text-field {
        padding: 10px;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        resize: vertical;
        margin: 6px 0 16px 0;
    }

    table tr:last-child {
        border-bottom: none;
    }

    /* Region record table */
    .record-article {
        margin-bottom: 100px;
    }

    .recordTable {
        margin-bottom: 10px;
    }

    .recordRow {
        width: 100%;
        display: flex;
        column-gap: 20px;
        border-bottom: 1px solid #ccc;
    }

    .recordFiller{
        width: 25px;
        height: 26px;
    }

    .recordAantal{
        width: 25px;
    }

    .recordRecordHouder{
        width: 200px;
    }

    .recordRecord{
        width: 115px;
    }

    .recordVideo{
        width: 45px;
    }

    .recordNotes{
        font-size: 12px;
        color: #a00;
        width: 100px;
    }

    .recordGroup {
        display: flex;
        align-content: space-between;
        gap: 10px;
    }

    .recordInput {
        flex: 1;
    }
    /* Endregion record table */

    /* Region edition table */
    .editionRow {
        width: 100%;
        display: flex;
        column-gap: 20px;
        border-bottom: 1px solid #ccc;
    }

    .editionNumber{
        width: 50px;
    }

    .editionLocation{
        width: 150px;
    }

    .editionYear {
        width: 50px;
    }

    .editionVisitors{
        width: 80px;
    }

    .editionTable {
        margin-bottom: 20px;
    }
    /* Endregion edition table */


    .container {
        width: 100%;
        height: 100%;
        text-align: center;
    }

    .bubblewrapper {
        display: block;
        position: absolute;
        height: 100%;
        width: 300px;
        left: 50%;
    }

    .bubblepath {
        height: 100%;
        padding: 0 1px;
        display: table-cell;
        width: 60px;
    }

    .bubble {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        position: absolute;
        background-color: #000;
        bottom: 0;
    }
}

@media only screen and (min-width: 2400px) {
    .records {
        text-underline: black;
    }
}

/* Begin region Juggler */
.juggler,
.juggler-body,
.juggler-arm-left,
.juggler-arm-right,
.juggler-head,
.juggler-leg,
.juggle-ball-1,
.juggle-ball-2,
.juggle-ball-4,
.juggle-ball-3,
.juggle-ball-5 {
  position: absolute;
}

.juggler {
    margin: auto;
    top: 15px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
}

.juggler-arm-left {
    top: 14px;
    height: 10px;
    width: 3px;
    left: 0px;
    border-radius: 2px 0 1px 0;
    background: #999;
    transform: rotate(30deg);
}

.juggler-arm-left-lower {
    position: absolute;
    top: 12px;
    height: 10px;
    width: 3px;
    left: -2px;
    border-radius: 1px;
    content: '';
    background: #999;
    transform: rotate(-45deg);
    transform-origin: bottom center;
    animation: juggleLeftArm 0.5s infinite 0.05s;
}

.juggler-arm-right {
    top: 14px;
    height:10px;
    width: 3px;
    left: 13px;
    background: #999;
    border-radius: 0 2px 0 1px;
    transform: rotate(-30deg);
}

.juggler-arm-right-lower {
    position: absolute;
    top: 12px;
    height:10px;
    width: 3px;
    left: 15px;
    content: '';
    border-radius: 1px;
    background: #999;
    transform: rotate(45deg);
    transform-origin: bottom center;
    animation: juggleRightArm 0.5s infinite 0.05s;
}

.juggler-body {
    width: 12px;
    top: 12px;
    height: 20px;
    left: 2px;
    border-radius: 6px 6px 0 0;
    background: #999;
}

.juggler-head {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    left: 3px;
    top: 0px;
    background: #999;
}

.juggler-leg {
    top: 35px;
    height: 10px;
    width:3px;
    left: 2px;
    border-radius: 2px;
    background: #999;
    /* background: linear-gradient(0deg, brown 20%, #00FFFF 20%); */
}

.juggler-leg::before {
    position: absolute;
    height: 10px;
    width: 3px;
    left: 9px;
    border-radius: 2px;
    content: '';
    background: #999;
    /* background: linear-gradient(0deg, brown 20%, #00FFFF 20%); */
}

.juggle-ball {
    height: 4px;
    width: 4px;
    border-radius: 2px;
    background: #DDD;
    position: absolute;
    z-index: 11;
    top: 5px;
}

.juggle-ball-left{
    left: -4px;
}

.juggle-ball-right{
    left: 16px;
}

.juggle-ball-1-left {
    background: green;
    animation: animationLeft 2.5s linear infinite;
}

.juggle-ball-1-right {
    background: green;
    animation: animationRight 2.5s linear infinite;
}

.juggle-ball-2-left {
    background: yellow;
    animation: animationLeft2 2.5s linear infinite;
}

.juggle-ball-2-right {
    background: yellow;
    animation: animationRight2 2.5s linear infinite;
}

.juggle-ball-3-left {
    background: red;
    animation: animationLeft3 2.5s linear infinite;
}

.juggle-ball-3-right {
    background: red;
    animation: animationRight3 2.5s linear infinite;
}

.juggle-ball-4-left {
    background: blue;
    animation: animationLeft4 2.5s linear infinite;
}

.juggle-ball-4-right {
    background: blue;
    animation: animationRight4 2.5s linear infinite;
}

.juggle-ball-5-left {
    background: purple;
    animation: animationLeft5 2.5s linear infinite;
}

.juggle-ball-5-right {
    background: purple;
    animation: animationRight5 2.5s linear infinite;
}

@keyframes animationLeft {
    0%    { transform: rotate(0deg) translateX(10px); visibility: visible; }
    50%   { transform: rotate(360deg) translateX(10px); visibility: hidden; }
    100%  { transform: rotate(360deg) translateX(10px); visibility: hidden; }
}

@keyframes animationRight {
    0%    { transform: rotate(-180deg) translateX(10px); visibility: hidden; }
    50%   { transform: rotate(180deg) translateX(10px); visibility: hidden; }
    100%  { transform: rotate(-180deg) translateX(10px); visibility: visible;}
}

@keyframes animationLeft2 {
    0%   { transform: rotate(0deg) translateX(10px); visibility: hidden; }
    19%  { transform: rotate(0deg) translateX(10px); visibility: hidden; }
    20%  { transform: rotate(0deg) translateX(10px); visibility: visible; }
    70%  { transform: rotate(360deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(120deg) translateX(10px); visibility: hidden; }
}

@keyframes animationRight2 {
    0%   { transform: rotate(-60deg) translateX(10px); visibility: visible; }
    20%  { transform: rotate(-180deg) translateX(10px); visibility: hidden; }
    70%  { transform: rotate(180deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(-60deg) translateX(10px); visibility: visible; }
}

@keyframes animationLeft3 {
    0%   { transform: rotate(288deg) translateX(10px); visibility: hidden; }
    39%  { transform: rotate(0deg) translateX(10px); visibility: hidden; }
    40%  { transform: rotate(0deg) translateX(10px); visibility: visible; }
    90%  { transform: rotate(360deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(288deg) translateX(10px); visibility: hidden; }
}

@keyframes animationRight3 {
    0%   { transform: rotate(120deg) translateX(10px); visibility: visible; }
    40%  { transform: rotate(-180deg) translateX(10px); visibility: hidden; }
    90%  { transform: rotate(180deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(120deg) translateX(10px); visibility: visible; }
}

@keyframes animationLeft4 {
    0%   { transform: rotate(288deg) translateX(10px); visibility: visible; }
    10%  { transform: rotate(360deg) translateX(10px); visibility: hidden; }
    60%  { transform: rotate(0deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(288deg) translateX(10px); visibility: visible; }
}

@keyframes animationRight4 {
    0%   { transform: rotate(180deg) translateX(10px); visibility: hidden; }
    10%  { transform: rotate(180deg) translateX(10px); visibility: hidden; }
    60%  { transform: rotate(-180deg) translateX(10px); visibility: visible; }
    61%  { transform: rotate(-180deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(180deg) translateX(10px); visibility: hidden; }
}

@keyframes animationLeft5 {
    0%   { transform: rotate(144deg) translateX(10px); visibility: visible; }
    30%  { transform: rotate(360deg) translateX(10px); visibility: hidden; }
    80%  { transform: rotate(0deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(144deg) translateX(10px); visibility: visible; }
}

@keyframes animationRight5 {
    0%   { transform: rotate(180deg) translateX(10px); visibility: hidden; }
    30%  { transform: rotate(180deg) translateX(10px); visibility: hidden; }
    80%  { transform: rotate(-180deg) translateX(10px); visibility: visible; }
    81%  { transform: rotate(-180deg) translateX(10px); visibility: hidden; }
    100% { transform: rotate(180deg) translateX(10px); visibility: hidden; }
}

@keyframes juggleLeftArm {
    50%   { transform: rotate(-140deg); }
}

@keyframes juggleRightArm {
    0%    { transform: rotate(140deg); }
    50%   { transform: rotate(45deg); }
    100%  { transform: rotate(140deg); }
}

/* End region Juggler */

/* Begin region Unicyle */

.unicycle,
.unicycle-wheel,
.unicycle-wheel-spokes,
.unicycle-pen,
.unicycle-floor,
.unicycle-pedals,
.unicycle-pedal,
.unicycle-pedal-pen,
.unicycle-seat {
  position: absolute;
}

.unicycle {
    margin: auto;
    top: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 25px;
    height: 50px;
    transform-origin: top center;
    animation: rockUnicycle 1.2s linear infinite;
}

.unicycle-pedals {
    z-index: 3;
    top: 22px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    transform-origin: center center;
    animation: rotatePedal 1.2s linear infinite;
}

.unicycle-pedal {
    z-index: 4;
    top: 10px;
    left: 3px;
    width: 4px;
    height: 2px;
    background-color: black;
    border-radius: 1px;
    transform-origin: center center;
    transform: rotate(45deg);
    animation: rockPedal 1.2s linear infinite;
}

.unicycle-pedal-pen {
    position: absolute;
    z-index: 3;
    top: 47.5%;
    left: 4px;
    width: 6px;
    height: 1px;
    background-color: grey;
    border-radius: 1px;
}

.unicycle-pen {
    z-index: 2;
    top: 8px;
    left: 10px;
    width: 4px;
    height: 25px;
    background-color: red;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.unicycle-seat {
    top: 2px;
    left: 2px;
    width: 20px;
    height: 6px;
    background-color: grey;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 2px;
}

.unicycle-seat::before {
    position: absolute;
    content: '';
    left: 5px;
    width: 10px;
    height: 2px;
    background-color: #dcc0a8;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.unicycle-wheel {
    top: 20px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: black;
}

.unicycle-wheel-inner {
    position: relative;
    z-index: 0;
    top: 22px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #dcc0a8;
}

.unicycle-wheel-spokes {
    z-index: 1;
    top: 2px;
    left: 2px;
    height: 20px;
    width: 20px;
    transform-origin: 10px 10px;
    animation: rotatePedal 1.2s linear infinite;
}

.unicycle-wheel-spoke {
    list-style: none;
    border-right: 1px solid #333;
    height: 20px;
    position: absolute;
    left: 10px;
}

@keyframes rotatePedal {
    0%  { transform: rotate(-90deg)}
    25% { transform: rotate(-180deg); }
    50% { transform: rotate(-90deg); }
    75% { transform: rotate(0deg); }
    100% { transform: rotate(-90deg); }
}

@keyframes rockPedal {
    0%   { transform: rotate(90deg) translateX(-1px) translateY(0px); }
    25%   { transform: rotate(180deg) translateX(-1px) translateY(0px); }
    50%  { transform: rotate(90deg) translateX(-1px) translateY(0px); }
    75%   { transform: rotate(0deg) translateX(-1px) translateY(0px); }
    100% { transform: rotate(90deg) translateX(-1px) translateY(0px); }
}

@keyframes rockUnicycle {
    25% { transform: translateX(0px) translateY(2px) rotate(20deg); }
    50% { transform: translateX(0px) translateY(0px) rotate(0deg); }
    75% { transform: translateX(0px) translateY(2px) rotate(-20deg); }
    50% { transform: translateX(0px) translateY(0px) rotate(0deg); }
}

/* End region Unicycle */

/* Begin region Diabolo */

.diabolo,
.diabolo-sticks,
.diabolo-stick-left,
.diabolo-stick-right,
.diabolo-stick-top,
.diabolo-diabolo,
.diabolo-inner,
.diabolo-spoke,
.diabolo-axle,
.diabolo-pin,
.diabolo-rope {
  position: absolute;
}

.diabolo {
    margin: auto;
    top: 5px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
}

.diabolo-rope {
    width: 40px;
    height: 30px;
    top: 15px;
    left: 5px;
    border: solid 1px #000;
    transform: rotateX(180deg);
    border-color: #000 transparent transparent transparent;
    border-radius: 50%/30px 30px 0 0;
    animation: whipRope 2s linear infinite;
}

.diabolo-diabolo {
    z-index: 1;
    width: 16px;
    height: 16px;
    top: 32px;
    left: 17px;
    border-radius: 8px;
    background-color: skyblue;
    animation: throwDiabolo 2s ease-out infinite;
}

.diabolo-inner {
    width: 12px;
    height: 12px;
    top: 2px;
    left: 2px;
    border-radius: 6px;
    background-color: snow;
    animation: rotate 1s linear infinite;
}

.diabolo-spoke {
    width: 3px;
    border-left: 1px dotted transparent;
    border-right: 1px solid transparent;
    border-bottom: 5px solid skyblue;
}

.diabolo-spoke-1 {
    top: 4px;
    transform: rotate(60deg);
}

.diabolo-spoke-2 {
    top: 8px;
    left: 5px;
    transform: rotate(-30deg);
}

.diabolo-spoke-3 {
    top: -1px;
    left: 4px;
    transform: rotate(150deg);
}

.diabolo-spoke-4 {
    top: 3px;
    left: 9px;
    transform: rotate(240deg);
}

.diabolo-axle {
    width: 6px;
    height: 6px;
    top: 3px;
    left: 3px;
    border-radius: 3px;
    background-color: steelblue;
}

.diabolo-pin {
    width: 2px;
    height: 2px;
    top: 2px;
    left: 2px;
    border-radius: 1px;
    background-color: black;
}

.diabolo-stick-left {
    width: 3px;
    height: 14px;
    top: 3px;
    left: 9px;
    background-color: black;
    border-radius: 1px 1px 0 0;
    transform: rotate(40deg);
    animation: turnLeftStick 2s linear infinite;
}

.diabolo-stick-right {
    width: 3px;
    height: 14px;
    top: 3px;
    left: 38px;
    background-color: black;
    border-radius: 1px 1px 0 0;
    transform: rotate(-40deg);
    animation: turnRightStick 2s linear infinite;
}

.diabolo-stick-top {
    width: 3px;
    height: 1px;
    border-radius: 30% 30% 0 0;
    background-color: silver;
}

@keyframes throwDiabolo {
    50% { transform: translateY(-60px); }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

@keyframes turnRightStick {
    10% { transform: rotate(-120deg) translateX(-5px) translateY(-5px); }
    75% { transform: rotate(-120deg) translateX(-5px) translateY(-5px); }
}

@keyframes turnLeftStick {
    10% { transform: rotate(120deg) translateX(5px) translateY(-5px); }
    75% { transform: rotate(120deg) translateX(5px) translateY(-5px); }
}

@keyframes whipRope{
    9% { height: 10px; }
    75% { height: 10px; }
}

/* End region Diabolo */

/* Begin region Devilstick */

.devilstick,
.line-top,
.line-center,
.line-bottom,
.devilstick-right-stick,
.devilstick-flowerstick,
.devilstick-flowerstick-top,
.devilstick-flowerstick-bottom,
.devilstick-left-stick {
  position: absolute;
}

.devilstick {
    margin: auto;
    top: 5px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
}

.devilstick-right-stick {
    z-index: 1;
    top: 5px;
    right: 5px;
    width: 0.08em;
    height: 20px;
    background-color: black;
    transform: rotateX(60deg) rotateZ(30deg) rotateY(30deg);
    border-radius: 1px;
    animation: moveRightStick 1s linear infinite;
}

.devilstick-left-stick {
    z-index: 1;
    top: 5px;
    left: 5px;
    width: 0.08em;
    height: 20px;
    background-color: black;
    transform: rotateX(60deg) rotateZ(-30deg) rotateY(-30deg);
    border-radius: 1px;
    animation: moveLeftStick 1s linear infinite;
}

.devilstick-flowerstick {
    top: 12px;
    left: 24px;
    width: 2px;
    height: 30px;
    background-color: red;
    transform-origin: center center;
    animation: moveFlowerStick 1s linear infinite;
}

.devilstick-flowerstick-top {
    top: -1px;
    left: -1px;
    width: 4px;
    height: 2px;
    background-color: whitesmoke;
    border-radius: 0 0 1px 1px;
}

.devilstick-flowerstick-bottom {
    bottom: -1px;
    left: -1px;
    width: 4px;
    height: 2px;
    background-color: whitesmoke;
    border-radius: 1px 1px 0 0;
}

.line-top {
    top: 3px;
    width: 2px;
    height: 3px;
    background-color: ivory;
}

.line-center {
    top: 13px;
    width: 2px;
    height: 4px;
    background-color: ivory;
}

.line-bottom {
    top: 24px;
    width: 2px;
    height: 3px;
    background-color: ivory;
}

.line-top::before{
    position: absolute;
    top: 1px;
    content: '';
    width: 2px;
    height: 1px;
    background-color: black;
}

.line-center::before{
    position: absolute;
    top: 1px;
    content: '';
    width: 2px;
    height: 2px;
    background-color: black;
}

.line-bottom::before{
    position: absolute;
    top: 1px;
    content: '';
    width: 2px;
    height: 1px;
    background-color: black;
}

@keyframes moveFlowerStick {
    0%   { transform: rotate(0deg) translateX(0px) translateY(-10px);   }
    25%  { transform: rotate(-30deg) translateX(-10px) translateY(-10px);  }
    50%  { transform: rotate(0deg) translateX(0px) translateY(-10px);   }
    75%  { transform: rotate(30deg) translateX(10px) translateY(-10px); }
    100% { transform: rotate(0deg) translateX(0) translateY(-10px); }
}

@keyframes moveLeftStick {
    50% { transform: rotateX(60deg) rotateZ(-30deg) rotateY(-30deg); }
    80% { transform: rotateX(60deg) rotateZ(-10deg) rotateY(-30deg); }
    100% { transform: rotateX(60deg) rotateZ(-30deg) rotateY(-30deg); }
}

@keyframes moveRightStick {
    0% { transform: rotateX(60deg) rotateZ(30deg) rotateY(30deg); }
    30% { transform: rotateX(60deg) rotateZ(10deg) rotateY(30deg); }
    50% { transform: rotateX(60deg) rotateZ(30deg) rotateY(30deg); }
    100% { transform: rotateX(60deg) rotateZ(30deg) rotateY(30deg); }
}

/* End region Devilstick */